<template>
    <div class="wrapper">

      <router-view></router-view>

        <!-- HeaderNavSix section -->
        <HeaderNav></HeaderNav>

        <div class="main-content">

            <!-- Breadcrumb section -->
            <BreadcrumbContact breadcrumbDetail="Contact us for anything you want to say" breadcrumbName="Get in Touch"></BreadcrumbContact>

            <!-- ContactSection section -->
            <ContactSection></ContactSection>

        </div>

        <!-- Banner section -->
        <BannerTwo></BannerTwo>

        <!-- Footer section -->
        <FooterSection></FooterSection>

    </div>
</template>

<script>
import HeaderNav from '@/components/HeaderNav.vue'
import BreadcrumbContact from '@/components/BreadcrumbContact.vue'
import ContactSection from '@/components/ContactSection.vue'
import FaqSectionTwo from '@/components/FaqSectionTwo.vue'
import FooterSection from '@/components/FooterSection.vue'
import BannerTwo from '@/components/BannerTwo.vue'

export default {
  components: {
    HeaderNav,
    BreadcrumbContact,
    ContactSection,
    FaqSectionTwo,
    FooterSection,
    BannerTwo
  }
}
</script>