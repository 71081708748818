<template>
    <div class="breadcrumb-area breadcrumb-height" data-bg-image="" :style="{backgroundImage: `url(${ detailBg })`}">
        <div class="container h-100">
            <div class="row h-100">
                <div class="col-lg-12">
                    <div class="breadcrumb-item">
                        <h6 class="breadcrumb-title">{{breadcrumbName}}</h6>
                        <h1 class="breadcrumb-detail">{{breadcrumbDetail}}</h1>
                    </div>
                </div>
            </div>
        </div>
        <div class="display-flex row">
            <div class="row">
                <div class="col-xl-4 col-lg-4 col-sm-12 border detail-desc mb-5 m-auto">
                    <img src="images/contact/icon/loc.png" alt="Contact Icon">
                    <p class="mt-3">Jl. H. Agus Salim Lt. 2 No.22 D-E, Jakarta Pusat,  10340</p>
                </div>
                <div class="col-xl-4 col-lg-4 col-sm-12 border detail-desc mb-5 m-auto">
                    <img src="images/contact/icon/phone.png" alt="Contact Icon">
                    <p class="mt-3">082213802060</p>
                </div>
                <div class="col-xl-4 col-lg-4 col-sm-12 border detail-desc mb-5 m-auto">
                    <img src="images/contact/icon/mail.png" alt="Contact Icon">
                    <p class="mt-3">thomas.robin@riset.ai</p>
                </div>
                <div class="row col-lg-12 border m-auto desktop">
                    <div class="col-xl-5 col-lg-5 col-sm-12">
                        <div class="form-field">
                            <p>Your Name</p>
                            <input type="text" name="con_name" v-model="data.name" placeholder="Name" :class="v$.data.name.$errors.length > 0 ? 'input-field-error' : 'input-field'">
                            <div class="input-error" style="font-size: 10px; max-height: 10px; margin-top: -1.2rem; color: red;" v-for="error of v$.data.name.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                            </div>
                        </div>
                        <div class="form-field">
                            <p>Email Address</p>
                            <input type="text" name="con_email" v-model="data.email" placeholder="Email" :class="v$.data.email.$errors.length > 0 ? 'input-field-error' : 'input-field'">
                            <div class="input-error" style="font-size: 10px; max-height: 10px; margin-top: -1.2rem; color: red;" v-for="error of v$.data.email.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                            </div>
                        </div>
                        <div class="form-field">
                            <p>Phone Number</p>
                            <input type="number" name="con_phone" v-model="data.phone" placeholder="Phone Number" :class="v$.data.phone.$errors.length > 0 ? 'input-field-error' : 'input-field'">
                            <div class="input-error" style="font-size: 10px; max-height: 10px; margin-top: -1.2rem; color: red;" v-for="error of v$.data.phone.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-7">
                        <div class="form-field">
                            <textarea name="con_message" v-model="data.message" placeholder="Message" :class="v$.data.message.$errors.length > 0 ? 'textarea-field-error' : 'textarea-field'"></textarea>
                            <div class="input-error" style="font-size: 10px; max-height: 10px; margin-top: -1.2rem; color: red;" v-for="error of v$.data.message.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                            </div>
                        </div>
                        <div class="form-field">
                            <button style="width: 100%" type="submit" value="submit" @click="sendForm()" class="btn purple-color" name="submit">
                                Send
                            </button>
                            <p v-if="send === true" style="width: 100%; text-align: center; margin-top: 5px;">Message Sended</p>
                        </div>
                    </div>
                </div>
                <div class="row col-lg-12 border m-auto mobile">
                    <div class="col-sm-12">
                        <div class="form-field">
                            <p>Your Name</p>
                            <input type="text" name="con_name" v-model="data.name" placeholder="Name" :class="v$.data.name.$errors.length > 0 ? 'input-field-error' : 'input-field'">
                            <div class="input-error" style="font-size: 12px; max-height: 10px; margin-top: -0.6rem; color: red; margin-right: auto;" v-for="error of v$.data.name.$errors" :key="error.$uid">
                                <p class="error-msg">{{ error.$message }}</p>
                            </div>
                        </div>
                        <div class="form-field">
                            <p>Email</p>
                            <input type="text" name="con_email" v-model="data.email" placeholder="Email" :class="v$.data.email.$errors.length > 0 ? 'input-field-error' : 'input-field'">
                            <div class="input-error" style="font-size: 12px; max-height: 10px; margin-top: -0.6rem; color: red; margin-right: auto;" v-for="error of v$.data.email.$errors" :key="error.$uid">
                                <p class="error-msg">{{ error.$message }}</p>
                            </div>
                        </div>
                        <div class="form-field">
                            <p>Phone Number</p>
                            <input type="number" name="con_phone" v-model="data.phone" placeholder="Phone Number" :class="v$.data.phone.$errors.length > 0 ? 'input-field-error' : 'input-field'">
                            <div class="input-error" style="font-size: 12px; max-height: 10px; margin-top: -0.6rem; color: red; margin-right: auto;" v-for="error of v$.data.phone.$errors" :key="error.$uid">
                                <p class="error-msg">{{ error.$message }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-7">
                        <div class="form-field">
                            <textarea name="con_message" placeholder="Message" v-model="data.message" :class="v$.data.message.$errors.length > 0 ? 'textarea-field-error' : 'textarea-field'"></textarea>
                            <div class="input-error" style="font-size: 12px; max-height: 10px; margin-top: -0.6rem; color: red; margin-right: auto;" v-for="error of v$.data.message.$errors" :key="error.$uid">
                                <p class="error-msg">{{ error.$message }}</p>
                            </div>
                        </div>
                        <div class="form-field">
                            <button style="width: 100%" type="submit" value="submit" @click="sendForm()" class="btn purple-color" name="submit">
                                Send
                            </button>
                            <p v-if="send === true" style="width: 100%; text-align: center; margin-top: 5px;">Message Sended</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

export default {
  setup () {
    return { v$: useVuelidate() }
  },
  props: ['breadcrumbName', 'breadcrumbTitle', 'breadcrumbDetail'],
  data () {
    return {
      introBg: 'images/breadcrumb/bg/bg.png',
      detailBg: 'images/breadcrumb/bg/detail-bg2.png',
      data: {
        name: '',
        email: '',
        phone: '',
        message: ''
      },
      send: false
    }
  },
  validations () {
      return {
        data: {
            email: {
                required,
                email
            },
            name: {
                required
            },
            phone: {
                required
            },
            message: {
                required
            }
        }
      }
  },
  methods: {
      async sendForm () {
          const result = await this.v$.$validate()
          if (result) {
              this.axios.post('https://dev.api.the-netwerk.com/api/RisetAI/H1wWlOLOSzW6F4HjLC1K/send-mail', this.data).then(response => {
                if (response.data.success) {
                    this.send = true
                    this.v$.$reset()
                    this.data = {
                        name: '',
                        email: '',
                        phone: '',
                        message: ''
                    }
                    setTimeout(() => {
                        this.send = false
                    }, 5000)
                }
            }).catch(error => {
                console.log(error)
            })
          }
      }
  }
}
</script>
<style scoped>
.breadcrumb-area {
    background-color: #0D0D2D;
}
.breadcrumb-title {
    color: #ffffff;
    font-size: 20px;
    line-height: 20px;
    margin-top: 30px;
}
.breadcrumb-detail {
    color: #ffffff;
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 72px;
    /* or 150% */

    text-align: center;
    letter-spacing: 0.03em;
}

.btn.purple-color {
    background-color: #CE097C;
    color: #ffffff;
    font-family: "Gordita";
    font-size: 16px;
    font-weight: 500;
    border-radius: 50px;
    padding: 25px 30px;
    height: 55px;
    line-height: 0px;
}

.display-flex {
    height: 0px;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -80px;
    margin-left: 10%;
    margin-right: 10%;
}

.display-flex div {
    background-color: white;
    padding: 18px 22px;
    z-index: 10;
    border-radius: 12px;
}

.detail-desc {
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    /* or 150% */

    text-align: center;
    padding: 50px 10px !important;
    letter-spacing: 0.03em;
    width: 32%;
    /* height: 175px; */
}

.detail-desc i{
    font-size: 38px;
}

.input-field-error, .textarea-field-error {
    background-color: #fafafa;
    border: 1px solid red;
    border-radius: 10px;
    color: #52565b;
    font-size: 15px;
    padding: 25px;
    width: 100%;
    height: 55px;
}

.textarea-field-error {
    margin-top: 30px;
    height: 285px;
}

.form-field p{
    text-align: left;
}

.mobile {
    display: none;
}

@media only screen and (min-width: 1200px) {
    
    .detail-desc {
        height: 175px;
    }
}

@media only screen and (max-width: 991px) {
    .breadcrumb-title {
        font-size: 16px;
    }

    .breadcrumb-height {
        height: 550px;
    }

    .display-flex {
        height: 0px;
        width: 90%;
        display: flex;
        margin-top: -70px;
        margin-left: 5%;
        margin-right: 5%;
    }
    
    .display-flex div {
        text-align: center;
        background-color: white;
        padding: 12px 38px;
        z-index: 10;
        border-radius: 12px;
    }
    
    .display-flex img {
        width: 8%;
        padding: 5px 5px;
    }

    .detail-desc {
        width: 100%;
    }

    .form-field {
        padding: 0px 0px;
    }
}

@media only screen and (max-width: 574px) {
    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }

    .display-flex div {
        text-align: center;
        background-color: white;
        padding: 8px 12px;
        z-index: 10;
        border-radius: 12px;
    }
}
</style>
